import { defineStore } from "pinia";
import type { MediaModel } from "../client/models/mediaModel";

export const useMediaStore = defineStore("medias", () => {
    const errorCallbackInvoker = (error: ActionError) => errorCallbacks.value.forEach(c => c(error))
    const errorCallbacks = ref<Set<(error: ActionError) => any>>(new Set())
    const collection = useItemWithQueryCollection<MediaModel, { dateOrder?: "asc" | "desc" }, {}>("/api/v1/medias", dateSorter, i => Promise.resolve(i), errorCallbackInvoker)
    const getContentUri = (id: string, isPreview: boolean) => "/api/v1/medias/" + id + "/" + (isPreview ? "preview" : "content")
    const updater = useItemUpdater<MediaModel>("/api/v1/medias/:id", i => collection.pushItemsInLocalCollection([i]), errorCallbackInvoker)
    const deleter = useItemDeleter("/api/v1/medias/:id", i => collection.items.value.delete(i), errorCallbackInvoker)

    return {
        ...collection,
        errorCallbacks,
        getContentUri,
        updateAsync: updater.updateItemAsync,
        deleteAsync: deleter.deleteItemsAsync,
    }
})